import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { AuthContext, useApp } from '../../context';
import {
	Center, Box, Flex,
	Button,
	FormControl,
	FormLabel,
	Input,
	Spinner,
	Select,
	Text,
	Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
	Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
	AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
	useToast,
	RadioGroup,
	Radio,
	Stack,
	Textarea,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	InputGroup,
	InputRightAddon,
} from '@chakra-ui/react';
import papaparse from 'papaparse';
import './styles.css'

const ProjectEvaluations = () => {
	const app = useApp();
	const toast = useToast()
	const [ loading, setLoading ] = useState(true);
	const [plans, setPlans] = useState([]);
	const [selectedPlan, setSelectedPlan] = useState('');

	const [moduleLoading, setModuleLoading] = useState(false);
	const [modules, setModules] = useState([]);
	const [selectedModule, setSelectedModule] = useState('');

	const [submissionLoading, setSubmissionsLoading] = useState(false);
	const [saveSubmissionLoading, setSaveSubmissionLoading] = useState(false);
	const [ updateSubmissionLoading, setUpdateSubmissionLoading ] = useState(false);
	const [submissions, setSubmissions] = useState([]);
	const [submissionsData, setSubmissionData] = useState({});
	const submissionsDataRef = useRef({});
	const [disabledUpdateSubmission, setDisabledUpdateSubmission] = useState(false);
	const [disabledSubmitSubmission, setDisabledSubmitSubmission] = useState(false);
	const [saveMessage, setSaveMessage] = useState("");
	
	const [uploadFile, setUploadFile] = useState("");
	const [uploading, setUploading] = useState(false);
	const [exporting, setExporting] = useState(false);
	const [inputKey, setInputKey] = useState(10);
	const [evalOption, setEvalOption] = useState('1'); 
	const [remarks, setRemarks] = useState({});
	const [remarkvalue, setRemarkvalue] = useState("");
	const [remarkId, setremarkId] = useState(null);
	const [scoreError, setScoreError] = useState(false);
	const [scoreErrPos, setScoreErrPos] = useState(null);

	const [isProCourse, setIsProCourse] = useState(false);
	const [openQuestion, setOpenQuestion] = useState(false);
	const [openGradingCriteria, setOpenGradingCriteria] = useState(false);
	
	let _switch = true;

	const userData = useContext(AuthContext);
	const [isAdmin, setisAdmin] = useState(userData.roles === "admin");

	const onChangeScore = (id, score, value) => {
		submissionsDataRef.current = {...submissionsDataRef.current, [id] : { ...submissionsDataRef.current[id], score }};
		setSubmissionData(submissionsDataRef.current);
	}

	const [addRemark, setAddRemark] = useState(false);
	const handleAddRemark = (subIndex) => {
		setAddRemark(true);
		setremarkId(subIndex); 
		setRemarkvalue(submissionsData[subIndex].remarks && submissionsData[subIndex].remarks !== "" ? submissionsData[subIndex].remarks : "");
	}
	const onCloseAddRemark = () => {
		setAddRemark(false);
		setRemarkvalue("");
		setremarkId(null);
	}
	const handleSaveRemark = () => {
		let newSubmissionData = submissionsData;
		newSubmissionData[`${remarkId}`].remarks = remarkvalue; 
		setSubmissionData(newSubmissionData);
		submissionApi(true, newSubmissionData);
	}
	 

	const [editSubmission, setEditSubmission] = useState(null);
	const onCloseEditSubmission = () => {
		setEditSubmission(null);
	}

	const [myAlert, setMyAlert] = useState({ open: false, title: '', body: '', buttons: [{ text: '', onClick: () => {} }]});
	const onCloseAlert = () => {
		setMyAlert({ open: false, title: '', body: '', buttons: []});
	}

	const alertRef = useRef();
	const getPlans = async () => {
		let response = await app.helloFambase('GET', 'dashboard/get-plan-evaluations');
		setPlans(response.data);
		setLoading(false);
	}

	useEffect(() => {
		getPlans();
	}, []);
	
	const getModules = async(plan) => {
		setModuleLoading(true);
		setSelectedModule('');

		let response = await app.helloFambase(`GET`, `dashboard/get-modules/${plan}`);

		setModules(response.data);
		setModuleLoading(false);
	}

	useEffect(() => {
		if(selectedPlan) getModules(selectedPlan);
	}, [selectedPlan])

	const handleExport = () => {
		setExporting(true);

		let d = [];

		submissions.forEach(key => {
			const item = submissionsData[key];
			const id = item.id;
			const question = item.question.replace(/(<([^>]+)>)/gi, "");
			const answer = item.answer ? item.answer.replace(/(<([^>]+)>)/gi, "") : "";
			const score = item.score ? item.score : "";

			d.push([id, question, answer, score]);
		});

		const csv = papaparse.unparse({
			fields: [
                'id',
                'question',
                'answer',
                'score'
			],
			data: d,
		});

        const planName = plans.filter(plan => plan.id.toString() === selectedPlan)[0].name;
        const moduleName = modules.filter(mod => mod.id.toString() === selectedModule.split('_')[0])[0].title;

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.setAttribute('hidden', '');
        a.setAttribute('href', url);

        a.download = `${planName}-${moduleName}-SUBMISSIONS.csv`;
        document.body.appendChild(a);
        
        a.click();

		setExporting(false);
	}
	
	const handleUpload = () => {
		if(uploadFile !== ""){
			setUploading(true);

			papaparse.parse(uploadFile, {
				delimiter: ',',
				newline: '',
				header: true,
				dynamicTyping: true,
          		skipEmptyLines: 'greedy',
          		complete: async data => {
					console.log(data);

					let blankFields = true;
					let toSend = submissionsData;

					for(let index = 0; index<data.data.length; index++) {
						const row = data.data[index];
						if(!row.id || row.id === ""){
							alert(`Please fill id field of row ${index+1}`);
							setUploading(false);
							break;
						}
						// else if(!row.question || row.question === ""){
						// 	alert(`Please fill question field of row ${index+1}`);
						// 	break;
						// }
						// else if(!row.answer || row.answer === ""){
						// 	alert(`Please fill answer field of row ${index+1}`);
						// 	break;
						// }
						else if(!row.score || row.score === ""){
							alert(`Please fill score field of row ${index+1}`);
							setUploading(false);
							break;
						}
						else{ 
							blankFields = false;
							const key = row.id;

							toSend[`${key}`].id = row.id;
							// toSend[`${key}`].answer = row.answer || "";
							// toSend[`${key}`].question = row.question || "";
							toSend[`${key}`].score = row.score;
						}
					}

					if(!blankFields){
						setSubmissionData(toSend);

						try{
							let response = await app.helloFambase(`POST`, `dashboard/save-project-submissions`, toSend);

							if(response.success) alert(`${data.data.length} rows updated successfully.`);
							else alert("Error in updating submissions.");

							setUploading(false);
						}catch(e){
							console.log("Error ", e);

							alert("Error in updating submissions.");
							setUploading(false);
						}
					}				
		  		}		
			})
		}

		setInputKey(Math.random().toString(50))
		setUploadFile("");
	}

	const getSubmissions = async (module, planId) => {
		const [projectId, moduleId] = module.split("_");

		setSubmissionsLoading(true);
		setDisabledUpdateSubmission(false);
		setDisabledSubmitSubmission(false);
		setSaveMessage("");

		let response = await app.helloFambase(`GET`, `dashboard/get-project-submissions/${moduleId}?plan_id=${planId}`); 
		console.log(response.data);
		const s = [], sData = {};
		submissionsDataRef.current = {};
		
		let willUpdate = false;
		
        for(let i = 0; i < response.data.data.length; i += 1) {
			s.push(response.data.data[i].id);
			sData[response.data.data[i].id] = response.data.data[i];
			
            if(response.data.data[i].willUpdate) willUpdate = true;
		}

		if(response.data.willSubmit === 0) setDisabledSubmitSubmission(true);
		
        setSubmissions(s);
		setSubmissionData(sData);
		setIsProCourse(response.data.isProCourse);
		
        submissionsDataRef.current = sData;
		
        setSubmissionsLoading(false);
		setDisabledUpdateSubmission(!willUpdate);
	}
	
	const updateSubmission = async (e) => {
		e.preventDefault();
			setMyAlert({
				open: true,
				title: 'Are you sure?',
				body: 'publish score for the submission',
				buttons: [{
					text: 'submit',
					onClick: async () => {
						try{
							setUpdateSubmissionLoading(true);

							let submitObj = {};

							for(let i=0; i<Object.keys(submissionsData).length; i++){
								let currKey = Object.keys(submissionsData)[i];
								if(submissionsData[currKey].score === null || submissionsData[currKey].score === undefined || submissionsData[currKey].score === "") continue;

								submitObj[currKey] = submissionsData[currKey];

								delete submitObj[currKey].question;
								delete submitObj[currKey].answer;
							}

							console.log('submitObj', submitObj);

							let response = await app.helloFambase(`POST`, `dashboard/update-project-submissions`, submissionsData);

							if(response.data === "success") {
								const newSubmissionData = {};
								
                                for(let i in submissionsData) {
									newSubmissionData[i] = { ...submissionsData[i], willUpdate: 0 };
								}

								setSubmissionData(newSubmissionData);
								
                                toast({
									title: 'Submission updated!',
									status: 'success',
									isClosable: true,
								});

								setDisabledUpdateSubmission(true);
							}
							else {
								throw new Error("Submission updated failed!");
							}
						}catch(err) {
							toast({
								title: 'Submission updated failed!',
								status: 'error',
								isClosable: true,
							})
						}
						finally {
							setUpdateSubmissionLoading(false);
							onCloseAlert();
						}
					}
				}]
			})
	}

	const validScores = (score, id, maxScore) => {
		console.log('score - ',score);

		if(score === null || score === undefined || score === "" || score === 0) {
			setScoreError(false);
			setScoreErrPos(null);
			return true;
		}

		if(score < 4 || score > maxScore) {
			if(_switch){
				alert(`Invalid score detected at submission id ${id}. Scores must be between 3 and ${maxScore || 11}`)
				_switch = !_switch;
			}
			setScoreError(true);
			setScoreErrPos(id);
			return false;
		}

		setScoreError(false);
		setScoreErrPos(null);
		return true;
	}

	const submissionApi = async (isRemark=false, subData=submissionsData) => {
		try{	
			setSaveSubmissionLoading(true);
			let validity = true;
			let toSend = {}
			for(var key of Object.keys(subData)) {
				validity = validScores(subData[key].score, subData[key].id, subData[key].maximumScore);
				if(!validity) return;
				const {id, maximumScore, remarks,score, willUpdate} = subData[key]
				
				toSend[key] = {
					id, 
					score,
					maximumScore,
					remarks,
					willUpdate
				}
				
			}
			
			console.log('scoreError - ', scoreError)

			if(validity && !scoreError){
				console.log('subData - ',subData)

				// let submitObj = {};

				// for(let i=0; i<Object.keys(subData).length; i++){
				// 	let currKey = Object.keys(subData)[i];
				// 	submitObj[currKey] = subData[currKey];

				// 	delete submitObj[currKey].question;
				// 	delete submitObj[currKey].answer;
				// }

				// console.log('submitObj - ',submitObj)
				console.log('to send - ', toSend)

				let response = await app.helloFambase(`POST`, `dashboard/save-project-submissions`, toSend);
				// console.log(subData)
				// 
            	if(response.data === "success") {
					toast({
						title: isRemark ? 'Remarks saved' : 'Submission saved!',
						status: 'success',
						isClosable: true,
					});

					const now = new Date()
					setSaveMessage(`last save ${now.toLocaleDateString()}, at ${now.toLocaleTimeString()}`);
				}
				else {
					throw new Error(`${isRemark ? 'Remarks' : 'Submission'} save failed!}`);
				}
			}else{
				alert(`Invalid score detected at submission id ${scoreErrPos}.`)
			}
			
		}catch(err) {
			console.log(err)
			toast({
				title: isRemark ? 'Remarks save failed' : 'Submission save failed!',
				status: 'error',
				isClosable: true,
			})
		}
		finally {
			setSaveSubmissionLoading(false);
		}
	}

	const saveSubmission = (e) => {
		e.preventDefault();
		submissionApi();
	}

	useEffect(() => {
		if(selectedModule) getSubmissions(selectedModule, selectedPlan);
	}, [selectedModule]);

	const description = useMemo(() => {
		return modules.find(m => String(m.id).trim() === String(selectedModule).trim());
	}, [selectedModule]);

	const hideBorder = {
		'borderBottom': 'none'
	}

	return (
		<Box>
			<Flex direction="column">
			{
				loading ? (
					<Center>
						<Spinner />
					</Center>
				) : (
					<>
					<Flex justifyContent={'space-between'}>
						<FormControl w={'40vw'}>
							<FormLabel>Plan</FormLabel>
							<Select value={selectedPlan} onChange={e => setSelectedPlan(e.target.value)} disabled={moduleLoading}>
								<option value=''>select plan</option>
								{
									plans.map(plan => (
										<option value={plan.id} key={`plan_${plan.id}`} >{plan.name}</option>
									))
								}
								</Select>
						</FormControl>
						{
							selectedPlan ? (
								<FormControl w={'40vw'}>
									<FormLabel>Module</FormLabel>
									{
										moduleLoading ? (
											<Center>
												<Spinner />
											</Center>
										) : (
											<Select value={selectedModule} onChange={e => setSelectedModule(e.target.value)} disabled={submissionLoading}>
											<option value=''>select module</option>
											{
												modules.map((module, index) => (
													<option value={`${module.id}_${module.module_id}`} key={index} >{module.title}</option>
												))
											}
										</Select>
										)
									}
								</FormControl>
							) : null
						}
					</Flex>
						{
							selectedPlan && selectedModule ? (
								<>
									{
										submissionLoading ? (
											<Center mt={5}>
												<Spinner />
											</Center>
										) : (
												<>
												{
													saveMessage ? (
														<Text mt={5} fontWeight="bold" color="yellow.500" >{saveMessage}</Text>
													) : null
												}
												{
													disabledSubmitSubmission ? (
														<Text mt={5} fontWeight="bold" color="yellow.500" >still receiving submission for this module</Text>
													) : null
												}
												{
													description ? (
														<Text mt={5} dangerouslySetInnerHTML={{ __html: description.description }} ></Text>
													) : null
												}

												<form onSubmit={updateSubmission}>
												
												<Flex mt="5" align="center">
													<Text>Total Submissions: {submissions.length}</Text>
													<Button ml="10" colorScheme="teal" onClick={() => setOpenQuestion(true)}>Question</Button>
													{!isProCourse ? 
														<a href="https://docs.google.com/document/d/19qVokrQmryjLPVBdrbemEk7MAbusAnynmaH8emKSLUw/" target="_blank" rel="noreferrer">
															<Button ml="10" colorScheme="teal">Grading Criteria</Button> 
														</a>
														: 
														<a href="https://docs.google.com/document/d/14Q_PqLykjbm-CznaLaV42zjU2FLnACp_NGIiSwFUG7c/edit" target="_blank" rel="noreferrer">
															<Button ml="10" colorScheme="teal">Grading Criteria</Button>
														</a>
													}
												</Flex>

												<Flex h={disabledSubmitSubmission ? '50vh' : '60vh'} style={{ overflowY: 'scroll' }}>
													<Table mt={5} >
														<Thead>
															<Tr>
																<Th>id</Th>
																<Th>email</Th>
																<Th>answer</Th>
																<Th>score</Th>
																<Th>remarks</Th>
															</Tr>
														</Thead>
														<Tbody>
															{
																submissions.map((submission, index) => {
																	let showBorder = true;
																	if(isProCourse){
																		let isLast = submissions.length === index + 1;
																		
																		if(!isLast && 
																			(submissionsData[submissions[index+1]].email === submissionsData[submission].email) &&
																			(submissionsData[submissions[index+1]].chapterId === submissionsData[submission].chapterId)
																		) {
																			showBorder = false;
																		}
																	}

																	return(
																		<Tr key={index}>
																			<Td sx={showBorder ? {} : hideBorder}>{submissionsData[submission].id}</Td>
																			<Td sx={showBorder ? {} : hideBorder}>{submissionsData[submission].email}</Td>
																			<Td sx={showBorder ? {} : hideBorder}>
																				<Box style={{ overflow: 'hidden', cursor: 'pointer' }} 
																					dangerouslySetInnerHTML={{ __html: submissionsData[submission].answer }} width="20rem" height="5rem" onClick={(e) => {
																						e.preventDefault();
																						e.stopPropagation();
																						setEditSubmission(submission);
																					}} 
																				/>
																			</Td>
																			<Td sx={showBorder ? {} : hideBorder}>
																				<InputGroup>
																					<NumberInput placeholder='score' disabled={!submissionsData[submission].willUpdate} type="number" min={4} max={submissionsData[submission].maximumScore} value={submissionsData[submission].score || ""} onBlur={(e) => {
																							validScores(e.target.value, submissionsData[submission].id, submissionsData[submission].maximumScore)
																							
																						}} 
																						onChange={(valStr, valNum) => onChangeScore(submission, valStr, valNum)} >
																						<NumberInputField/>
																						<NumberInputStepper>
																							<NumberIncrementStepper/>
																							<NumberDecrementStepper/>
																						</NumberInputStepper>
																					</NumberInput>
																					<InputRightAddon children={`/${submissionsData[submission].maximumScore}`} />
																				</InputGroup>
																			</Td>
																			<Td sx={showBorder ? {} : hideBorder}>
																				<Button onClick={() => handleAddRemark(submission)}>
																					{submissionsData[submission].remarks && submissionsData[submission].remarks !== "" ? "Edit remark" : "Add Remark"}
																				</Button>
																			</Td>
																		</Tr>
																)
															})}
														</Tbody>
													</Table>
												</Flex>

												<Flex mt={10} direction={"column"}>
													<RadioGroup>
														<Stack direction='row' onChange={e => setEvalOption(e.target.value)} value={evalOption}>
															<Radio value={'1'} isChecked={evalOption === '1'}>Manual Entry</Radio>
															<Radio value={'2'} isChecked={evalOption === '2'}>Export</Radio>
															<Radio value={'3'} isChecked={evalOption === '3'}>Upload</Radio>
														</Stack>
													</RadioGroup>
													{evalOption === '1' &&
														<Flex mt={5}>
															<Button isLoading={saveSubmissionLoading} mr={5} variant={"solid"} colorScheme="red" type='button' onClick={saveSubmission}>Save Progress</Button>
															{isAdmin && <Button disabled={ disabledUpdateSubmission || disabledSubmitSubmission } variant={"solid"} colorScheme="red" type='submit'>Publish Scores</Button>}
														</Flex> 
													}

													{
														evalOption === '2' &&
														<Flex mt={5}>
															<Button mr={10} w={"200px"} variant={"solid"} colorScheme="red" type='button' onClick={handleExport} isLoading={exporting}>Export Submissions</Button>
														</Flex>
													}

													{
														evalOption === '3' &&
														<Flex mt={5} w={'50%'}>
															<Input mr={2} px={5} py={2} type="file" onChange={e => setUploadFile(e.target.files[0])} accept=".csv" key={inputKey}/>
															<Button w={"70%"} variant={"solid"} colorScheme="red" type='button' onClick={handleUpload} isLoading={uploading} >Upload Submissions</Button>
														</Flex>
													}
													
												</Flex>
												
												</form>
											</>
										)
									}
								</>
							) : null
						}

						<Modal size={"6xl"} isOpen={!!editSubmission} onClose={onCloseEditSubmission}>
								<ModalOverlay />
								<ModalContent>
									<ModalHeader>View submission</ModalHeader>
									<ModalCloseButton />
									<ModalBody>
										{
											editSubmission ? (
												<div>
													<FormControl>
														<FormLabel>email</FormLabel>
														<FormLabel>{submissionsData[editSubmission].email}</FormLabel>
													</FormControl>
													<FormControl>
														<FormLabel>answer</FormLabel>
														<Box mt={5} mb={5} className='answer' dangerouslySetInnerHTML={{ __html: submissionsData[editSubmission].answer }} />
													</FormControl>
													<FormControl>
														<FormLabel>score</FormLabel>
														<NumberInput 
															required 
															placeholder='score' 
															disabled={!submissionsData[editSubmission].willUpdate} 
															type="number" 
															min={4} 
															max={submissionsData[editSubmission].maximumScore} 
															value={submissionsData[editSubmission].score || ""} 
															onBlur={(e) => {
																e.preventDefault();
																validScores(e.target.value, submissionsData[editSubmission].id, submissionsData[editSubmission].maximumScore)
															}} 
															onChange={(valStr, valNum) => onChangeScore(editSubmission, valStr, valNum)} 
														>
															<NumberInputField/>
															<NumberInputStepper>
																<NumberIncrementStepper/>
																<NumberDecrementStepper/>
															</NumberInputStepper>
														</NumberInput>
													</FormControl>
												</div>
											) : null
										}
									</ModalBody>

									<ModalFooter>
										<Button colorScheme='red' mr={3} onClick={onCloseEditSubmission}>
											ok
										</Button>
									</ModalFooter>
								</ModalContent>
						</Modal>
						

						<Modal size={"5xl"}  isOpen={addRemark} onClose={onCloseAddRemark}>
							<ModalOverlay />
							<ModalContent>
								<ModalHeader>Add Remarks</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<FormControl height={"40vh"}>
										<Textarea h={"60%"} maxH={"60%"} mb={"5%"} value={remarkvalue} onChange={(e) => setRemarkvalue(e.target.value)}/>
										<Button w={"100%"} variant={"solid"} colorScheme={"red"} onClick={handleSaveRemark} isLoading={saveSubmissionLoading}> Save remarks </Button>
									</FormControl>
								</ModalBody>
							</ModalContent>
						</Modal>

						<AlertDialog
							isOpen={myAlert.open}
							leastDestructiveRef={alertRef}
							onClose={onCloseAlert}
						>
							<AlertDialogOverlay>
								<AlertDialogContent>
									<AlertDialogHeader fontSize='lg' fontWeight='bold'>
										{myAlert.title}
									</AlertDialogHeader>
									<AlertDialogBody>
										{myAlert.body}
									</AlertDialogBody>
									<AlertDialogFooter>
										<Button ref={alertRef} onClick={onCloseAlert}>
											Cancel
										</Button>
										{
											myAlert.buttons.map((button, index) => (
												<Button key={`button_${index}`} variant='ghost' isLoading={updateSubmissionLoading} onClick={button.onClick} ml={3}>
													{button.text}
												</Button>
											))
										}
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialogOverlay>
						</AlertDialog>

						<Modal size="6xl" isOpen={openQuestion} onClose={() => setOpenQuestion(false)}>
							<ModalOverlay />

							<ModalContent>
								<ModalHeader>Question</ModalHeader>
								<ModalCloseButton />

								<ModalBody>
									<Text pb="5" 
										dangerouslySetInnerHTML={{ 
											__html: !isProCourse ? Object.values(submissionsData).length ? Object.values(submissionsData)[0].question : '' 
											: Object.values(submissionsData).length ? [...new Set(Object.values(submissionsData).map((submission) => submission.question))].join('<br /><br /><hr /><hr />	<br />') : ''
										}} 
									/>
								</ModalBody>
							</ModalContent>
						</Modal>

						<Modal size="6xl" isOpen={openGradingCriteria} onClose={() => setOpenGradingCriteria(false)}>
							<ModalOverlay />

							<ModalContent>
								<ModalHeader>Grading Criteria</ModalHeader>
								<ModalCloseButton />

								<ModalBody pb="5">
									<Table>
										<Thead>
											<Tr>
												<Th>Scores</Th>
												<Th>Scoring Parameters</Th>
											</Tr>
										</Thead>
										<Tbody>
											<Tr>
												<Td>4</Td>
												<Td>You may need to relook at your work. It does not include enough information or it not relevant to the topic given. </Td>
											</Tr>
											<Tr>
												<Td>5</Td>
												<Td>You've understood the assignment, but currently your writing needs more clarity and thought. It would be a good idea to rewrite the assignment to make it more clear and coherent. </Td>
											</Tr>
											<Tr>
												<Td>6</Td>
												<Td>Your assignment is clear and coherent, however, we miss the tone and voice, that makes it your own individual piece.  Let's try adding more emphasis on the  brand/personal voice. </Td>
											</Tr>
											<Tr>
												<Td>7</Td>
												<Td>You've done a good job - we like what you have written. You have ticked off all theoretical parameters to make this a good piece. With a little bit more polish, you are good to go! </Td>
											</Tr>
											<Tr>
												<Td>8</Td>
												<Td>Kudos! You are almost there. You have been able to make this your own piece. Good job! </Td>
											</Tr>
											<Tr>
												<Td>9</Td>
												<Td>We love the quality of your writing, clarity of thought and the tone that you have adopted. Add this to your portfolio ASAP. </Td>
											</Tr>
											<Tr>
												<Td>10</Td>
												<Td>We never thought we would give this score - but you blew our minds. Your work was what we were looking for!</Td>
											</Tr>
										</Tbody>
									</Table>
								</ModalBody>
							</ModalContent>
						</Modal>
					</>
				)
			}
			</Flex>
		</Box>
	)
}

export default ProjectEvaluations;